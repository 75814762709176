#contactNumber {
  width: 125px;
  box-sizing: border-box;
  margin: 6px;
  padding: 5px;
  display: inline-block;
  border: 1px solid hsla(0, 0%, 31%, 0.718);
  border-radius: 5px;
  background: #f1f1f1;
}

#pan {
  border: 1px solid black;
  border-radius: 10px;
  text-align: center;
}

th {
  padding: 0.5rem;
  text-align: center;
  color: #fff;
  font-weight: normal;
  background-color: #6c8aec;
}

td {
  padding: 0.5rem;
  text-align: center;
}

table {
  margin: auto;
  width: 80%;
  border: 1px solid #13397f;
  border-collapse: collapse;
  border-radius: 10px;
}

.scroll-container {
  overflow: auto;
}