.custom-textarea {
  min-width: 210px;
  min-height: 400px;
  resize: none;
}

@media (min-width: 600px) {
  .custom-textarea {
    width: 300px; /* Set the width for desktop screens */
  }
}

@media (min-width: 800px) {
  .custom-textarea {
    width: 400px; /* Set the width for desktop screens */
  }
}

@media (min-width: 1000px) {
  .custom-textarea {
    width: 600px; /* Set the width for desktop screens */
  }
}

/* Set styles for the buttons */
.cancel-btn {
  padding: 5px 8px;
  margin: 5px;
  font: 10px;
  text-align: center;
  border: 1px solid;
  cursor: pointer;
  border-radius: 10px;
}

.cancel-btn {
  background-color: #ffffff;
  color: #000000;
}

.cancel-btn:hover {
  opacity: 1;
}

.cancel-btn:active {
  transform: scale(0.95);
}
