/* Set a style for the search-accounts-btn */
.loading-btn {
  background-color: #13397f;
  color: #ffffff;
  padding: 5px 8px;
  margin: 5px;
  font: 10px;
  text-align: center;
  border: 1px solid;
  cursor: pointer;
  border-radius: 10px;
}

.loading-btn-gif {
  width: 40px;
}

.loading-btn:hover {
  opacity: 1;
}

.loading-btn:active {
  transform: scale(0.95);
}
