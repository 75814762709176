/* Set styles for the buttons */
.delete-btn, .cancel-btn {
    padding: 5px 8px;
    margin: 5px;
    font: 10px;
    text-align: center;
    border: 1px solid;
    cursor: pointer;
    border-radius: 10px;
  }

  .delete-btn {
    background-color: red;
    color: #ffffff;
  }

  .cancel-btn {
    background-color: #ffffff;
    color: #000000;
  }
  
  .delete-btn:hover, .cancel-btn:hover {
    opacity: 1;
  }
  
  .delete-btn:active, .cancel-btn:active {
    transform: scale(0.95);
  }