.banner {
    width: 100%;
    height: 100vh;
    background-color: white;
    background-position: center;
    background-size: contain;
  }
  
  .container {
    padding: 16px;
  }